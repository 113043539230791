<template>
  <div class="layout--full-page">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "FullScreenView",
}
</script>

<style scoped>

.layout--full-page{
  height: 100vh;
}

</style>
